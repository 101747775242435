import React, { useEffect } from "react";
import "./all_product.css";
import G1 from "../../assets/ostali_proizvodi/g1.jpg";
import G2 from "../../assets/ostali_proizvodi/g2.jpg";
import G3 from "../../assets/ostali_proizvodi/g3.jpg";
import G4 from "../../assets/ostali_proizvodi/g4.jpg";
import G5 from "../../assets/ostali_proizvodi/g5.jpg";
import MetaDecorator from "../../functions/MetaDecorator";

export default function GarnitureGumica(props) {
  useEffect(() => {
    const section = document.querySelector("#scroll");
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <div className="parent_all_product">
      <MetaDecorator
        title="Max Tehnika Knic - Garniture gumica"
        description="Veliki izbor garnitura gumica za sve cilindre iz našeg kataloga. Pogledajte naš vebsajt i pozovite za više detalja."
      />
      <div className="child_all_product">
        <h1 id="scroll" className="h1_all_product">
          {/* {props.language == "S" ? "" : props.language == "E" ?" " :"" } */}
          {props.language == "S"
            ? "  Garnitura gumica 1468160-1 za glavni cilindar IMT 560, IMT 577"
            : props.language == "E"
            ? "Sealing kit 1468160-1 for brake master cylinder IMT 560, IMT 577"
            : "Gummi-Garnitur 1468160-1 für den Hauptzylinder IMT 560, IMT 577"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img className="image_part" src={G1} alt="cilindar" />
          </div>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1 className="h1_all_product">
          {props.language == "S"
            ? "          Garnitura gumica 1468162-2 za dva cilindra točka IMT 560, IMT 577"
            : props.language == "E"
            ? "Sealing kit 1468162-2 for two brake slave cylinders IMT 560, IMT 577"
            : "Gummi-Garnitur 1468162-2 für 2 Radzylinder IMT 560, IMT 577"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img className="image_part" src={G2} alt="cilindar" />
          </div>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1 className="h1_all_product">
          {props.language == "S"
            ? "          Garnitura gumica za dva glavna cilindra i dva cilindra točka ZMAJ 132,          ZMAJ 133"
            : props.language == "E"
            ? "Sealing kit for two brake master cylinders and two brake slave cylinders for ZMAJ 132, ZMAJ 133"
            : "Gummi-Garnitur für 2 Hauptzylinder und 2 Radzylinder ZMAJ 132, ZMAJ 133"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img className="image_part" src={G3} alt="cilindar" />
          </div>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1 className="h1_all_product">
          {props.language == "S"
            ? "          Garnitura gumica za glavni cilindar TAM-1468112"
            : props.language == "E"
            ? " Sealing kit for master cylinder TAM-1468112"
            : "Gummi-Garnitur für den Hauptzylinder TAM-1468112"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img className="image_part" src={G4} alt="cilindar" />
          </div>
        </section>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1 className="h1_all_product">
          {props.language == "S"
            ? "          Garniture gumica za prednji cilindar točka TAM-1468191"
            : props.language == "E"
            ? "Sealing kit for brake slave cylinder TAM 1468191, front wheel"
            : "Gummi-Garnitur für Radzylinder vorne TAM-1468191"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img className="image_part" src={G5} alt="cilindar" />
          </div>
        </section>
      </div>
    </div>
  );
}
