import React from 'react'
import PropTypes from "prop-types"
import {Helmet} from "react-helmet";

const MetaDecorator = (props) => {
  return (
    <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
        </Helmet>
  );
}

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired ,
  description: PropTypes.string.isRequired,
}

export default MetaDecorator;
