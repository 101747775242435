import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Link,
} from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import Product from "./pages/product/product.js";
import Navbar from "./pages/navbar/navbar";
import Contact from "./pages/contact/contact";
import About from "./pages/about/about";
import Footer from "./pages/footer/footer";
import Reparation from "./pages/reparacija/reparacija";
import GarnitureGumica from "./pages/all_product/garnitureGumica";
import MasinskaObradaMetala from "./pages/all_product/masinskaObradaMetala";
import KocionaKlesta from "./pages/all_product/kocionaKlesta";
import VinogradarskaSidra from "./pages/all_product/vinogradarskaSidra";
import Logo from "./assets/Logo_transparent.png";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import Flag from "react-world-flags";
import { LanguageRounded } from "@mui/icons-material";

function App() {
  // loader

  const [language, setLanguage] = useState("S");
  // let scrollRef = useRef(null);
  // let scrollRefProduct = useRef(null);
  // scrollRef = scrollRefProduct;

  const handleLanguage = (lan) => {
    setLanguage(lan);
  };

  return (
    <div>
      <Navbar handleLanguage={handleLanguage} />

      <Switch>
        <Route
          path="/contact"
          component={() => <Contact language={language}></Contact>}
        />
        <Route
          path="/product"
          component={() => <Product language={language}></Product>}
        />
        <Route
          exact
          path="/"
          component={() => <Product language={language}></Product>}
        />
        <Route
          path="/about"
          component={() => <About language={language}></About>}
        />
        <Route
          path="/reparation"
          component={() => <Reparation language={language}></Reparation>}
        />
        <Route
          exact
          path="/vinogradarska_sidra"
          component={() => (
            <VinogradarskaSidra language={language}></VinogradarskaSidra>
          )}
        />
        <Route
          exact
          path="/masinska_obrada"
          component={() => (
            <MasinskaObradaMetala language={language}></MasinskaObradaMetala>
          )}
        />
        <Route
          exact
          path="/garniture_gumica"
          component={() => (
            <GarnitureGumica language={language}></GarnitureGumica>
          )}
        />
        <Route
          exact
          path="/kociona_klesta"
          component={() => <KocionaKlesta language={language}></KocionaKlesta>}
        />
        <Route
          path="*"
          component={() => <Product language={language}></Product>}
        />
      </Switch>
      <Footer language={language}> </Footer>
    </div>
  );
}

export default App;
