import React, { useEffect } from "react";
import "./all_product.css";
import MasinskaObrada from "../../assets/ostali_proizvodi/masinskaObrada.jpg";
import MetaDecorator from "../../functions/MetaDecorator";

export default function MasinskaObradaMetala(props) {
  useEffect(() => {
    const section = document.querySelector("#scroll");
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);
  return (
    <div className="parent_all_product">
      <MetaDecorator
        title="Max Tehnika Knic - Mašinska obrada metala"
        description="Vršimo uslužnu mašinsku obradu metala.Kvalitetna izrada i niske cene. Pogledajte naš vebsajt i pozovite za više detalja."
      />
      <div className="child_all_product">
        <h1 id="scroll" className="h1_all_product">
          {props.language == "S"
            ? "Mašinska obrada metala"
            : props.language == "E"
            ? "Metalworking processes"
            : "Maschinelle Metallbearbeitung"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img className="image_part" src={MasinskaObrada} alt="cilindar" />
          </div>
        </section>
      </div>
    </div>
  );
}
