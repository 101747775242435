import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./reparacija.css";

export default function CarouselFunction({
  handleSelectedItem,
  handleClick,
  isActive,
}) {
  // let ref = useRef(null);
  let [currentImage, setCurrentImage] = useState(null);
  function importAll(r) {
    let images = [];
    let i = 0;
    r.keys().forEach((item) => {
      images[i] = r(item);
      i++;
    });
    return images;
  }

  const images = importAll(
    require.context("../../assets/reparacije", false, /\.(png|jpe?g|svg)$/)
  );

  // const onClickItem = (props) => {
  //   console.log(props);
  //   currentImage.current = props;
  // };
  // const brojac = [0, 9, 18, 27, 36, 45, 54, 63, 81];

  return (
    <div>
      {/* <div className="selectedImage_display">
        <div>
 <img className="selectedImage" src={currentImage}></img>
        </div>
      </div> */}
      <div className="image_div_carousel">
        <Carousel
          autoPlay={5000}
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          // onSwipeMove={handleTouchMove}
          emulateTouch={true}
          swipeable={true}
          swipeScrollTolerance={window.innerWidth > 768 ? 10 : 50}
          preventMovementUntilSwipeScrollTolerance={true}
          // onClickItem={onClickItem}
        >
          {(() => {
            let td = [];
            for (let i = 0; i < 81; i = i + 9) {
              td.push(
                <div>
                  <div className="wrapper">
                    <div
                      className={isActive ? "carouselImg_blur item" : "  item"}
                      onClick={() => {
                        handleSelectedItem(images[i]);
                        handleClick();
                      }}
                    >
                      <img className="carouselImg" src={images[i]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 1]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 1]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 2]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 2]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 3]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 3]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 4]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 4]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 5]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 5]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 6]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 6]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 7]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 7]} />
                    </div>
                    <div
                      onClick={() => {
                        handleSelectedItem(images[i + 8]);
                        handleClick();
                      }}
                      className={isActive ? "carouselImg_blur item" : "  item"}
                    >
                      <img className="carouselImg" src={images[i + 8]} />
                    </div>
                  </div>
                </div>
              );
            }
            return td;
          })()}
        </Carousel>
      </div>
    </div>
  );
}
