import React, { useEffect } from "react";
import "./all_product.css";
import KocionaKlesta from "../../assets/ostali_proizvodi/kocionaKlesta.jpg";
import MetaDecorator from "../../functions/MetaDecorator";

export default function KocionaKlestaComponent(props) {
  useEffect(() => {
    const section = document.querySelector("#scroll");
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <div className="parent_all_product">
      <MetaDecorator
        title="Max Tehnika Knic - kočiona klešta"
        description=" Konstruišemo sve moguće dimenzije kočionih klipova.Kvalitetna izrada i niske cene. Pogledajte naš vebsajt i pozovite za više detalja."
      />
      <div className="child_all_product">
        <h1 id="scroll" className="h1_all_product">
          {props.language == "S"
            ? "Klipovi za kočiona klešta"
            : props.language == "E"
            ? " Brake caliper pistons"
            : "Kolben für Bremssattel"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img className="image_part" src={KocionaKlesta} alt="cilindar" />
          </div>
        </section>
      </div>
    </div>
  );
}
