import React, { useState, Suspense, useRef, useMemo, useEffect } from "react";
import "./product.css";

import Cilindar108 from "../../assets/cilindri/cilindri webp/108.webp";
import Cilindar160 from "../../assets/cilindri/cilindri webp/160.webp";
import Cilindar162 from "../../assets/cilindri/cilindri webp/162.webp";
import Cilindar191 from "../../assets/cilindri/cilindri webp/191.webp";
import Cilindar237 from "../../assets/cilindri/cilindri webp/237.webp";
import Cilindar237_flip from "../../assets/cilindri/cilindri webp/237_flip.webp";

import Cilindar036 from "../../assets/cilindri/cilindri webp/cil.webp";
import Cilindar321 from "../../assets/cilindri/cilindri webp/Fap.webp";
import KutijaVelika from "../../assets/cilindri/cilindri webp/kutijaVelika.webp";
import MokraKocnica from "../../assets/cilindri/cilindri webp/MokraKocnica.webp";
import CilindarTam from "../../assets/cilindri/cilindri webp/TAM192.webp";
import CilindarZetor from "../../assets/cilindri/cilindri webp/Zetor.webp";
import Animation_cylinder from "../../assets/home_drawing_cylinder/Cilindar_svg.js";

import Question from "../../assets/animations/question.svg";

import MetaDecorator from "../../functions/MetaDecorator";
import { useIntersection } from "react-use";
import arrowUp from "../../assets/arrowUp.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

function Product(props) {
  const [MousePosition, setMousePosition] = useState({
    moving: false,
  });

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();
  // // scrollRefProduct = scrollRef;

  const threshold = 1;

  const sectionRef0 = useRef(null);
  const sectionRef = useRef(null);
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);
  const sectionRef4 = useRef(null);
  const sectionRef5 = useRef(null);

  const sectionRefIMG1 = useRef(null);
  const sectionRefIMG2 = useRef(null);
  const sectionRefIMG3 = useRef(null);
  const sectionRefIMG4 = useRef(null);
  const sectionRefIMG5 = useRef(null);
  const sectionRefIMG6 = useRef(null);
  const sectionRefIMG7 = useRef(null);
  const sectionRefIMG8 = useRef(null);
  const sectionRefIMG9 = useRef(null);
  const sectionRefIMG10 = useRef(null);
  const sectionRefIMG11 = useRef(null);
  const sectionRefIMG12 = useRef(null);
  const sectionRefIMG13 = useRef(null);
  const sectionRefIMG14 = useRef(null);

  const intersection0 = useIntersection(sectionRef0, {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  });
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  });

  const intersection2 = useIntersection(sectionRef2, {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  });

  const intersection3 = useIntersection(sectionRef3, {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  });

  const intersection4 = useIntersection(sectionRef4, {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  });
  const intersection5 = useIntersection(sectionRef5, {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  });

  const intersectionIMG1 = useIntersection(sectionRefIMG1, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG2 = useIntersection(sectionRefIMG2, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG3 = useIntersection(sectionRefIMG3, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG4 = useIntersection(sectionRefIMG4, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG5 = useIntersection(sectionRefIMG5, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG6 = useIntersection(sectionRefIMG6, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG7 = useIntersection(sectionRefIMG7, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG8 = useIntersection(sectionRefIMG8, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG9 = useIntersection(sectionRefIMG9, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG10 = useIntersection(sectionRefIMG10, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG11 = useIntersection(sectionRefIMG11, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG12 = useIntersection(sectionRefIMG12, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG13 = useIntersection(sectionRefIMG13, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const intersectionIMG14 = useIntersection(sectionRefIMG14, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  function handleMouseMove(ev) {
    setMousePosition({ moving: true });
  }

  // function handleMouseClick(ev) {
  //   setCurrentModel({ model: ev });
  // }

  return (
    <div>
      <MetaDecorator
        title="Max Tehnika Knic - Proizvodi "
        description="Nakon dugogodišnjeg iskustva u proizvodnji kočionih cilindara, raznih komponenata za putnička i teretna vozila, u raznovrsnoj mašinskoj obradi metala, mi Vam nudimo kvalitet i sigurnost."
      />
      {window.innerWidth > 768 ? (
        <img
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          className="arrowUp"
          src={arrowUp}
          alt="strelica"
        />
      ) : (
        <div></div>
      )}

      <div className="parent">
        <section className="child">
          <section className="section_divide_first">
            <div>
              <div
                ref={sectionRef0}
                style={{
                  width: "100px",
                  heigth: "100px",

                  position: "absolute",
                }}
              ></div>
              <h1
                className={
                  intersection0 && intersection0.intersectionRatio < 1
                    ? "hidden2 h1TextMAX"
                    : "show2 h1TextMAX"
                }
              >
                {/* {props.language == "S" ? "" : props.language == "E" ? "" : "" } */}
                {props.language == "S"
                  ? "nudi sve za vaše kočione sisteme"
                  : props.language == "E"
                  ? "offers everything for your brake systems"
                  : "bietet alles für Ihre Bremssysteme"}
              </h1>
              <h2
                className={
                  intersection0 && intersection0.intersectionRatio < 1
                    ? "hidden2 "
                    : " show2"
                }
              >
                {props.language == "S"
                  ? "razni delovi za poljoprivredne i radne mašine kao i za putnička vozila"
                  : props.language == "E"
                  ? "various parts for agricultural and working machines as well as for vehicles"
                  : "diverse Teile für Land- und Arbeitsmaschinen sowie für Personenkraftwagen"}
              </h2>

              <br></br>
              <div className={"h1ArrowText"}>
                <h2
                  className={
                    intersection0 && intersection0.intersectionRatio < 1
                      ? "hidden2 h1Arrow"
                      : " show2 h1Arrow"
                  }
                  style={{ fontSize: "3.5vh" }}
                >
                  {props.language == "S"
                    ? " uvek na stanju"
                    : props.language == "E"
                    ? "always available"
                    : "immer verfügbar"}
                  &#x2192;
                </h2>
                <h2
                  className={
                    intersection0 && intersection0.intersectionRatio < 1
                      ? "hidden2 h1Arrow"
                      : " show2 h1Arrow"
                  }
                  style={{ fontSize: "3.5vh" }}
                >
                  {props.language == "S"
                    ? "visoki kvalitet"
                    : props.language == "E"
                    ? "high quality"
                    : "gute Qualität"}
                  &#x2192;
                </h2>
                <h2
                  className={
                    intersection0 && intersection0.intersectionRatio < 1
                      ? "hidden2 h1Arrow"
                      : " show2 h1Arrow"
                  }
                  style={{ fontSize: "3.5vh" }}
                >
                  {props.language == "S"
                    ? "povoljne cene"
                    : props.language == "E"
                    ? "affordable prices"
                    : "bezahlbare Preise"}
                </h2>
              </div>
            </div>
            {/* <h2
              className={
                intersection0 && intersection0.intersectionRatio < 1
                  ? "hidden2 "
                  : " show2"
              }
            >
              uvek na stanju &#x2192; visoki kvalitet &#x2192; povoljne cena
            </h2> */}
          </section>
          <section className="section_divide_second">
            <div className="svg_div_animation">
              <Animation_cylinder
                language={props.language}
              ></Animation_cylinder>
            </div>
            {/* <div className="canvas_div"> </div> */}
          </section>
        </section>

        <section className="childNewColor">
          <section className="section_divide_first_2">
            <Link style={{ textDecoration: "none" }} to="/">
              <div>
                <div
                  ref={sectionRef}
                  style={{
                    width: "100px",
                    heigth: "100px",

                    position: "absolute",
                  }}
                ></div>
                <h1
                  onClick={executeScroll}
                  className={
                    intersection && intersection.intersectionRatio < 1
                      ? " h1TextTitle hidden2 "
                      : "  h1TextTitle show2"
                  }
                >
                  {props.language == "S"
                    ? " Kočioni cilindri"
                    : props.language == "E"
                    ? "Brake cylinders"
                    : "Bremszylinder"}
                </h1>

                <h2
                  className={
                    intersection && intersection.intersectionRatio < 1
                      ? " hidden2 "
                      : "show2 "
                  }
                >
                  {props.language == "S"
                    ? " preciznim tehnološkim procesima dobijeni su cilindri koji su atestirani na 100 000 promena na probnom stolu"
                    : props.language == "E"
                    ? "New cylinders, that are tested on 100,000 changes on the test bench, are produced by using precise technological processes"
                    : "Präzise technologische Prozesse führten zu Zylindern, die für 100.000 Wechsel auf dem Prüfstand zertifiziert wurden"}
                </h2>
              </div>
            </Link>

            <br></br>

            <Link style={{ textDecoration: "none" }} to="/masinska_obrada">
              <div>
                <div
                  ref={sectionRef4}
                  style={{
                    width: "100px",
                    heigth: "100px",

                    position: "absolute",
                  }}
                ></div>
                <h1
                  className={
                    intersection4 && intersection4.intersectionRatio < 1
                      ? "h1TextTitle hidden2 "
                      : "h1TextTitle show2 "
                  }
                >
                  {props.language == "S"
                    ? "  Mašinska obrada metala"
                    : props.language == "E"
                    ? "Metalworking processes"
                    : "Maschinelle Metallbearbeitung"}
                </h1>
                <h2
                  className={
                    intersection4 && intersection4.intersectionRatio < 1
                      ? "hidden2 "
                      : "show2 "
                  }
                >
                  {props.language == "S"
                    ? " vršimo uslužnu mašinsku obradu metala"
                    : props.language == "E"
                    ? "Services for all kinds of metalworking processes are offered"
                    : "Wir führen Servicebearbeitung von Metall durch"}
                </h2>
              </div>
            </Link>

            <br></br>

            <Link style={{ textDecoration: "none" }} to="/kociona_klesta">
              <div>
                <div
                  ref={sectionRef5}
                  style={{
                    width: "100px",
                    heigth: "100px",

                    position: "absolute",
                  }}
                ></div>
                <h1
                  className={
                    intersection5 && intersection5.intersectionRatio < 1
                      ? "h1TextTitle  hidden2 "
                      : "h1TextTitle  show2 "
                  }
                >
                  {props.language == "S"
                    ? "Klipovi za kočiona klešta"
                    : props.language == "E"
                    ? " Brake caliper pistons"
                    : "Kolben für Bremssattel"}
                </h1>
                <h2
                  className={
                    intersection5 && intersection5.intersectionRatio < 1
                      ? "hidden2 "
                      : "show2 "
                  }
                >
                  {props.language == "S"
                    ? " Konstruišemo sve moguće dimenzije kočionih klipova"
                    : props.language == "E"
                    ? "All possible dimensions of brake pistons are produced"
                    : "Wir konstruieren alle möglichen Abmessungen von Bremskolben"}
                </h2>
              </div>
            </Link>
          </section>
          <section className="section_divide_second_2">
            <div>
              <div
                ref={sectionRef2}
                style={{
                  width: "100px",
                  heigth: "100px",

                  position: "absolute",
                }}
              ></div>
              <Link style={{ textDecoration: "none" }} to="/reparation">
                <h1
                  className={
                    intersection2 && intersection2.intersectionRatio < 1
                      ? "h1TextTitle hidden2 "
                      : "h1TextTitle show2 "
                  }
                >
                  {props.language == "S"
                    ? "Reparacije kočionih cilindara"
                    : props.language == "E"
                    ? "Reparation of brake cylinders"
                    : "Reparatur der Bremszylinder"}
                </h1>
              </Link>

              <h2
                className={
                  intersection2 && intersection2.intersectionRatio < 1
                    ? "hidden2 "
                    : "show2 "
                }
              >
                {props.language == "S"
                  ? "Inovativnih procesima dobija se tako reći nov cilindar sa garancijom od 2 godine"
                  : props.language == "E"
                  ? "Practically new brake cylinders, with a 2-year warranty, are produced by using innovative processes"
                  : "Innovative Prozesse produzieren einen neuen Zylinder mit 2 Jahren Garantie"}
              </h2>
            </div>
            <div className="logo-container">
              <div
                ref={sectionRef3}
                style={
                  window.innerWidth > 768
                    ? {
                        width: "100px",
                        heigth: "100px",

                        position: "absolute",
                      }
                    : {
                        width: "100px",
                        heigth: "100px",
                        marginTop: "100%",
                        position: "absolute",
                      }
                }
              ></div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> IMT</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2  "
                }
              >
                <p className="section_logo_font"> IMR</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Zastava</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> TAM</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> FAP</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> ZMAJ</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Indos</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Pobeda</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Zetor</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Belarus</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Class</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Masey Ferguson</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> New Holland</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> John Deer</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Universal</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Fendt</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Vaktra</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Fiat</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Toyota</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Caterpillar</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Volvo</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> DAF</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> IVECO</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Mercedes</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Skania</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Daymler</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Renault</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> MAN</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> King</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Jungheinrich AG</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Mitsubishi Logisnext</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> CROWN EQUIPMENT</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> HYMP</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Anhui Heli Co.</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Hangcha</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Manitou</p>
              </div>
              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font"> Doosan</p>
              </div>

              <div
                className={
                  intersection3 && intersection3.intersectionRatio < 1
                    ? " logo hidden2  "
                    : " logo show2 "
                }
              >
                <p className="section_logo_font">...</p>
              </div>
            </div>
          </section>
        </section>

        {/*
//slikee// */}
        <section className="image_section" id="scroll" ref={scrollRef}>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Glavni cilindar 1468160 IMT 560, IMT 577"
              : props.language == "E"
              ? "Brake master cylinder IMT 1468160 for IMT 560, IMT 577"
              : "Hauptbremszylinder 1468160 IMT 560, IMT 577"}
          </h1>
          {/* <div
            style={{
              position: "absolute",
              background: "red",
              width: "200px",
              height: "200px",
              marginTop: "20%",
            }}
          ></div> */}
          <img
            ref={sectionRefIMG1}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG1 && intersectionIMG1.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar160} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Cilindar točka 1468162 IMT 560, IMT 577"
              : props.language == "E"
              ? "Brake slave cylinder IMT 1468162 for 560, IMT 577"
              : "Radzylinder 1468162 IMT 560, IMT 577"}
          </h1>

          <img
            ref={sectionRefIMG2}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG2 && intersectionIMG2.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar162} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? "Cilindar točka – mokra kočnica, IMT 560/577"
              : props.language == "E"
              ? "Brake slave cylinder IMT – wet brakes for IMT 560/577"
              : "Radzylinder – Nasse Bremse, IMT 560/577"}
          </h1>

          <img
            ref={sectionRefIMG7}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG7 && intersectionIMG7.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={MokraKocnica} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Glavni cilindar 1468237 ZMAJ 132, ZMAJ 133"
              : props.language == "E"
              ? "Brake master cylinder ZMAJ 1468237 for ZMAJ 132, ZMAJ 133"
              : "Hauptbremszylinder 1468237 ZMAJ 132, ZMAJ 133"}
          </h1>

          <img
            ref={sectionRefIMG3}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG3 && intersectionIMG3.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar237} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? "Cilindar točka 1468108 ZMAJ 132, ZMAJ 133"
              : props.language == "E"
              ? "Brake slave cylinder ZMAJ 1468108 for ZMAJ 132, ZMAJ 133"
              : "Radzylinder 1468108 ZMAJ 132, ZMAJ 133"}
          </h1>

          <img
            ref={sectionRefIMG4}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG4 && intersectionIMG4.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar108} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Glavni cilindar 1468112 TAM 1001, 75, 60, 80T3, T5, A5, A6, 110, T10, 125, T10K, 125T10G, 260, A116M, A116P, A120T, 120L Viljuškar INDOS D2- 5t i Viljuškar POBEDA T.U.50"
              : props.language == "E"
              ? "Brake master cylinder TAM 1468112 for TAM 1001, 75, 60, 80T3, T5, A5, A6, 110, T10, 125, T10K, 125T10G, 260, A116M, A116P, A120T, 120L Forklift INDOS D2- 5t and Forklift POBEDA T.U.50"
              : " Hauptbremszylinder 1468112 TAM 1001, 75, 60, 80T3, T5, A5, A6, 110, T10, 125, T10K, 125T10G, 260, A116M, A116P, A120T, 120L Gabelstapler INDOS D2- 5t und Gabelstapler POBEDA T.U.50"}
          </h1>

          <img
            ref={sectionRefIMG5}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG5 && intersectionIMG5.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={CilindarTam} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? "Cilindar točka- prednji 1468191 TAM 2001, 60, 75, 80, T35, A50, A60 Viljuškar INDOS el. 1,5t i 2,5t i TAM A190 Viljuškar POBEDA TU 32 i TU 35"
              : props.language == "E"
              ? "Brake slave cylinder TAM 1468191, front wheel, for TAM 2001, 60, 75, 80, T35, A50, A60 Forklift INDOS el. 1,5t i 2,5t and TAM A190 Forklift POBEDA TU"
              : "Radzylinder – vorne 1468191 TAM 2001, 60, 75, 80, T35, A50, A60 Gabelstapler INDOS el. 1,5t i 2,5t und TAM A190 Gabelstapler POBEDA TU 32 i TU 35"}
          </h1>

          <img
            ref={sectionRefIMG6}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG6 && intersectionIMG6.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar191} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Pomoćni cilindar kvačila 1468321 FAP- Mercedes"
              : props.language == "E"
              ? "Clutch slave cylinder 1468321 FAP- Mercedes"
              : "Hilfszylinder-Kupplung 1468321 FAP- Mercedes"}
          </h1>

          <img
            ref={sectionRefIMG8}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG8 && intersectionIMG8.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar321} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? "Glavni cilindar 1468190 ZETOR "
              : props.language == "E"
              ? "Master cylinder 1468190 ZETOR"
              : " Hauptbremszylinder 1468190 ZETOR"}
          </h1>

          <img
            ref={sectionRefIMG9}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG9 && intersectionIMG9.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={CilindarZetor} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? "cilindar 510036 IMT (IMT 5100,5106,5135,5136)"
              : props.language == "E"
              ? "Master cylinder 510036 IMT (IMT 5100,5106,5135,5136)"
              : " Hauptbremszylinder 510036 IMT (IMT 5100,5106,5135,5136)  "}
          </h1>

          <img
            ref={sectionRefIMG10}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG10 && intersectionIMG10.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar036} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? "Cilindar točka – mokra kočnica, IMT 5100/5106/5135/5136"
              : props.language == "E"
              ? "Brake slave cylinder IMT – wet brakes for IMT 5100/5106/5135/5136"
              : "Radzylinder – Nasse Bremse, IMT 5100/5106/5135/5136"}
          </h1>

          <img
            ref={sectionRefIMG11}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG11 && intersectionIMG11.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={MokraKocnica} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Glavni cilindar 1468239, Viljuškar INDOS Elektro 1,5t i 2,5t"
              : props.language == "E"
              ? "Master cylinder 1468239, Forklift INDOS electric 1,5t and 2,5t"
              : "Hauptbremszylinder 1468239, Viljuškar INDOS Elektro 1,5t i 2,5t"}
          </h1>

          <img
            ref={sectionRefIMG12}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG12 && intersectionIMG12.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar237} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Glavni cilindar 1468238, Viljuškar Litostroj, TAM 5000"
              : props.language == "E"
              ? "Master cylinder 1468238, Forklift Litostroj, TAM 5000"
              : "Hauptbremszylinder 1468238, Viljuškar Litostroj, TAM 5000"}
          </h1>

          <img
            ref={sectionRefIMG13}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG13 && intersectionIMG13.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar237_flip} alt="cilindar" />
          </div>
        </section>

        <section>
          <h1 className="image_h1">
            {props.language == "S"
              ? " Glavni cilindar 1468328, Viljuškar POBEDA TU – 35"
              : props.language == "E"
              ? " Master cylinder 1468328, Forklift POBEDA TU – 35   "
              : "Hauptbremszylinder 1468328, Viljuškar POBEDA TU – 35"}
          </h1>

          <img
            ref={sectionRefIMG14}
            className="box_image"
            src={KutijaVelika}
            alt="kutija"
          />
          <div
            className={
              intersectionIMG14 && intersectionIMG14.intersectionRatio < 1
                ? " image_div hidden3  "
                : " image_div show3 "
            }
          >
            <img className="image_part" src={Cilindar237} alt="cilindar" />
          </div>
        </section>

        <section className="childQuestion">
          <img className="questionSVG" src={Question} />
        </section>
      </div>
    </div>
  );
}

export default Product;
