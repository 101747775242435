import React from "react";
import {
  FaFacebookF,
  FaGoogle,
  FaInstagram,
  FaMapMarkerAlt,
} from "react-icons/fa";

import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import "./footer.css";

export default function App() {
  return (
    <MDBFooter className="bg-light text-center text-white">
      <MDBContainer className="p-4 pb-0">
        <section className="section_icons">
          {/* <MDBBtn
            floating
            className="m-1"
            style={{
              position: "relative",
              backgroundColor: "#3b5998",
              borderRadius: "25px",
            }}
            href="https://www.facebook.com/maxtehnikaknic/"
            role="button"
          > */}
          <a
            style={{ textDecoration: "none", background: "none" }}
            href="https://www.facebook.com/maxtehnikaknic/"
          >
            <div
              className="div_icon"
              style={{ backgroundColor: "#3b5998", borderRadius: "25px" }}
            >
              <FaFacebookF color="white" className="svg_icon" />
            </div>
          </a>

          <a
            style={{ textDecoration: "none", background: "none" }}
            href="https://www.google.com/search?q=max+tehnika+knic&oq=max+tehnika+knic+&aqs=edge.0.69i59j69i60l2j69i64.4608j0j4&sourceid=chrome&ie=UTF-8"
          >
            <div
              className="div_icon"
              style={{ backgroundColor: "#EA4335", borderRadius: "25px" }}
            >
              <FaGoogle className="svg_icon" style={{ color: "white" }} />
            </div>
          </a>

          <a
            style={{ textDecoration: "none", background: "none" }}
            href="https://www.instagram.com/max_tehnika_knic/"
          >
            <div
              className="div_icon"
              style={{ backgroundColor: "#DD2A7B", borderRadius: "25px" }}
            >
              <FaInstagram className="svg_icon" style={{ color: "white" }} />
            </div>
          </a>

          <a
            style={{ textDecoration: "none", background: "none" }}
            href="https://www.google.com/maps/dir//max+tehnika+knic/@43.8587436,20.1532271,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47571941fefa9b3d:0x4120d0ab299c6c5b!2m2!1d20.7247528!2d43.9396222"
          >
            <div
              className="div_icon"
              style={{ backgroundColor: "#34a853", borderRadius: "25px" }}
            >
              <FaMapMarkerAlt className="svg_icon" style={{ color: "white" }} />
            </div>
          </a>
          {/* <MDBBtn
            floating
            className="m-1"
            style={{ backgroundColor: "#55acee", borderRadius: "25px" }}
            href="#!"
            role="button"
          >
            <MDBIcon fab icon="twitter" />
          </MDBBtn> */}
        </section>
      </MDBContainer>
    </MDBFooter>
  );
}
