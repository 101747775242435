import { React, useEffect } from "react";
import "./contact.css";
import mapsPNG from "../../assets/maps.png";
import MetaDecorator from "../../functions/MetaDecorator";

const Contact = (props) => {
  useEffect(() => {
    const section = document.querySelector("#scroll");
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <div className="parent_contact">
      <MetaDecorator
        title="Max Tehnika Knic - Kontakt"
        description="Proizvodnja i reparacija kočionih cilindara,remont kočionih cilindara, restauracija i zamena stare unutrašnje konstrukcije za novu, proizvodnja klipova za kočiona klešta,proizvodnja vinogradarskih sidara, mašinska obrada metala :struganje, glodanje,brušenje, honovanje, bravarski radovi."
      />
      <section className="child_contact">
        <section className="section_divide_first_contact">
          <h1 id="scroll">
            {" "}
            {props.language == "S"
              ? "Kontakt:"
              : props.language == "E"
              ? "Contact:"
              : "Kontakt"}{" "}
          </h1>
          <h2>
            {" "}
            {props.language == "S"
              ? "Radno vreme:"
              : props.language == "E"
              ? "Working hours:"
              : "Öffnungzeiten:"}{" "}
          </h2>
          <h3>
            {props.language == "S"
              ? "08:00 – 20:00:"
              : props.language == "E"
              ? "08:00 – 20:00:"
              : "08:00 – 20:00"}
          </h3>
          <h2>
            {" "}
            {props.language == "S"
              ? "Kontakt podaci:"
              : props.language == "E"
              ? "Contact us:"
              : "Kontaktangaben:"}{" "}
          </h2>
          <h3>
            {props.language == "S"
              ? "telefon: "
              : props.language == "E"
              ? "phone:"
              : "Handynummer:"}
            <br></br>
            <h5>
              {props.language == "S"
                ? "  Vojin Maksimović dip. maš. ing. -  065 3221324 "
                : props.language == "E"
                ? "Vojin Maksimović dip. maš. ing. -  065 3221324"
                : "Vojin Maksimović dip. maš. ing. -  065 3221324"}
            </h5>

            <h5>
              {props.language == "S"
                ? "  Mihailo Maksimović dipl. inž. elektr. i računar.  -  061 1605506"
                : props.language == "E"
                ? "Mihailo Maksimović dipl. inž. elektr. i računar.  -  061 1605506"
                : "Mihailo Maksimović dipl. inž. elektr. i računar.  -  061 1605506"}
            </h5>
            <br></br>
          </h3>
          <h3>
            <a href="https://p.facebook.com/maxtehnikaknic/">
              facebook: https://p.facebook.com/maxtehnikaknic/
            </a>
          </h3>
          <h3>
            <div style={{ display: "flex", flexFlow: "row" }}>
              e-mail: <h5>maxtehnikaknic@gmail.com</h5>
            </div>
          </h3>
          <h2>
            {" "}
            {props.language == "S"
              ? "Informacije"
              : props.language == "E"
              ? "Information"
              : "Informationen"}
            :
          </h2>
          <h3>
            {props.language == "S"
              ? "Proizvodnja i reparacija kočionih cilindara, proizvodnja klipova za kočiona klešta, mašinska obrada metala :struganje, glodanje,brušenje, honovanje, bravarski radovi."
              : props.language == "E"
              ? "We manufacture original brake parts and brake calipers pistons and also perform metalworking processes (lathing, milling, grinding, honing and other machining processes)."
              : "Herstellung und Reparatur der Bremszylinder, Kolben für Bremssattel, maschinelle Metalbearbeitung :abdrehen, fräsen, schleifen, feinschleifen, Schloßerarbeit."}
          </h3>
          <h2>
            {" "}
            {props.language == "S"
              ? "Adresa"
              : props.language == "E"
              ? "Adress"
              : "Adresse"}{" "}
            :
          </h2>

          <h3> Max tehnika knic, ​ Ulica obor kneza Todosija 83, 34240 Knić</h3>
        </section>
        <section className="section_divide_second_contact">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=max tehnika knic&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://embed-googlemap.com"></a>
            </div>
          </div>
          {/* <a href="https://www.google.com/maps/dir//max+tehnika+knic/@43.8587436,20.1532271,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47571941fefa9b3d:0x4120d0ab299c6c5b!2m2!1d20.7247528!2d43.9396222">
            <img
              className="mapsPNG"
              style={{ width: "20vh", marginTop: "10vh" }}
              src={mapsPNG}
            ></img>
          </a> */}
          {/* <a href="https://www.google.com/maps/dir//max+tehnika+knic/@43.8587436,20.1532271,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47571941fefa9b3d:0x4120d0ab299c6c5b!2m2!1d20.7247528!2d43.9396222">
            <h1 style={{ color: "#5d5d5e" }}>
              {props.language == "S"
                ? "pronadjite nas na mapama"
                : props.language == "E"
                ? "find us on maps"
                : "Finden Sie uns auf Karten"}
            </h1>
          </a> */}
        </section>
      </section>
    </div>
  );
};

export default Contact;

// Radno vreme:
// 08:00 – 20:00

// Kontakt podaci:
// telefon: 065 3221324 , 061 1605506
// facebook:  https://p.facebook.com/maxtehnikaknic/
// e-mail: maxtehnikaknic@gmail.com

// Informacije:
// Proizvodnja i reparacija kočionih cilindara, proizvodnja klipova za kočiona klešta, mašinska obrada metala (struganje, glodanje, brušenje, honovanje, bravarski radovi).

// Adresa:
// Ulica obor kneza Todosija 83, 34240 Knić
