import React, { useState, forwardRef, useRef, useEffect } from "react";
import "./navbar.css";
import Logo from "../../assets/Logo_transparent.png";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Flag from "react-world-flags";

const Navbar = ({ handleLanguage }) => {
  let [visible, setVisible] = useState(true);
  let [visibleIcon, setVisibleIcon] = useState(false);
  let [language, setLanguage] = useState("S");
  const [isActive, setIsActive] = useState(false);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const changeVisibility = (props) => {
    if (props) {
      setVisible((visible = !visible));
    } else {
      setVisibleIcon((visibleIcon = !visibleIcon));
    }
  };

  const changeLanguage = (props) => {
    if (props == "S") {
      setLanguage("S");
      handleLanguage("S");
    } else if (props == "E") {
      setLanguage("E");
      handleLanguage("E");
    } else {
      setLanguage("G");
      handleLanguage("G");
    }
  };

  const change_page = () => {
    if (window.location.pathname == "/product" || "") {
      const section = document.querySelector("#scroll");
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
    }
  };

  return (
    <div className="navbar">
      <img src={Logo} className="maxTehnika" alt="Max tehnika knic" />

      {
        windowSize.current[0] > 767 ? (
          <ul className="navigation">
            <li>
              <Link style={{ textDecoration: "none" }} to="/">
                <p>
                  {language == "S"
                    ? "Početna"
                    : language == "E"
                    ? "Home"
                    : "Startseite"}
                </p>
              </Link>
            </li>

            <li>
              <div>
                <div
                  onMouseLeave={(ev) => changeVisibility(true)}
                  className="product_meni"
                >
                  <Link style={{ textDecoration: "none" }} to="/product">
                    <p
                      style={{ color: visible ? "#5c5c5c" : "#1c1d20" }}
                      onMouseOver={(ev) => changeVisibility(true)}
                    >
                      {language == "S"
                        ? "Proizvodi"
                        : language == "E"
                        ? "Products"
                        : "Producte"}
                    </p>
                  </Link>
                  <Link
                    to="/product"
                    style={{
                      textDecoration: "none",
                      visibility: visible ? " visible " : "hidden",
                    }}
                    onClick={change_page}
                  >
                    <span className="navbar_span">
                      {language == "S"
                        ? "Kočioni cilindri"
                        : language == "E"
                        ? "Brake cylinders"
                        : "Bremszylinder"}
                    </span>
                  </Link>

                  <Link
                    style={{
                      textDecoration: "none",
                      visibility: visible ? " visible " : "hidden",
                    }}
                    to="/reparation"
                  >
                    <span className="navbar_span" style={{ marginTop: "-5px" }}>
                      {language == "S"
                        ? "Reparacija kočionih cilindara"
                        : language == "E"
                        ? "Reparation of brake cylinders"
                        : "Reparatur der Bremszylinder"}
                    </span>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      visibility: visible ? " visible " : "hidden",
                    }}
                    to="/kociona_klesta"
                  >
                    <span className="navbar_span" style={{ marginTop: "20px" }}>
                      {language == "S"
                        ? "Klipovi za kočiona klešta"
                        : language == "E"
                        ? "Brake caliper pistons"
                        : "Kolben für Bremssattel"}
                    </span>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      visibility: visible ? " visible " : "hidden",
                    }}
                    to="/garniture_gumica"
                  >
                    <span className="navbar_span" style={{ marginTop: "42px" }}>
                      {language == "S"
                        ? " Garniture gumica"
                        : language == "E"
                        ? "Sealing kits"
                        : "Gummi-Garnitur"}
                    </span>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      visibility: visible ? " visible " : "hidden",
                    }}
                    to="/vinogradarska_sidra"
                  >
                    <span className="navbar_span" style={{ marginTop: "65px" }}>
                      {language == "S"
                        ? "Vinogradarska sidra"
                        : language == "E"
                        ? "Vineyard screw anchors"
                        : "Weinbau Anker"}
                    </span>
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      visibility: visible ? " visible " : "hidden",
                    }}
                    to="/masinska_obrada"
                  >
                    <span className="navbar_span" style={{ marginTop: "86px" }}>
                      {language == "S"
                        ? "Mašinska obrada metala"
                        : language == "E"
                        ? "Metalworking processes"
                        : "Maschinelle Metallbearbeitung"}
                    </span>
                  </Link>
                </div>
              </div>
            </li>

            <li>
              <Link style={{ textDecoration: "none" }} to="/about">
                <p>
                  {language == "S"
                    ? "O nama"
                    : language == "E"
                    ? "About"
                    : "Über uns"}
                </p>
              </Link>
            </li>

            <li>
              <Link style={{ textDecoration: "none" }} to="/contact">
                <p>
                  {" "}
                  {language == "S"
                    ? "Kontakt"
                    : language == "E"
                    ? "Contact"
                    : "Kontakt"}
                </p>
              </Link>
            </li>

            <li>
              <div
                onMouseLeave={(ev) => changeVisibility(false)}
                className="icon_meni"
              >
                <div
                  onMouseOver={(ev) => changeVisibility(false)}
                  // style={{
                  //   textDecoration: "none",
                  //   width: "10vh",
                  //   height: "5.5vh",
                  // }}
                >
                  <div
                    className="language_div"
                    style={{ display: "flex", flexFlow: "row" }}
                  >
                    <Link style={{ textDecoration: "none" }}>
                      <p>
                        {language == "S"
                          ? "Jezik"
                          : language == "E"
                          ? "language"
                          : "Sprache"}
                      </p>
                    </Link>
                    <LanguageTwoToneIcon
                      style={{
                        marginTop: "5vh",
                        fontSize: "4vh",

                        position: "relative",
                        zIndex: "-2",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    textDecoration: "none",
                    visibility: visibleIcon ? " visible " : "hidden",
                  }}
                >
                  <span
                    className="navbar_span"
                    onClick={(ev) => changeLanguage("S")}
                  >
                    <Flag
                      style={{ marginTop: "-30px", paddingRight: "2vh" }}
                      code="srb"
                      width="25"
                    />
                    Serbian
                  </span>
                </div>
                <div
                  style={{
                    textDecoration: "none",
                    visibility: visibleIcon ? " visible " : "hidden",
                  }}
                >
                  <span
                    className="navbar_span"
                    onClick={(ev) => changeLanguage("E")}
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    <Flag
                      style={{ paddingRight: "2vh" }}
                      code="gb"
                      width="25"
                    />
                    English
                  </span>
                </div>
                <div
                  style={{
                    textDecoration: "none",
                    visibility: visibleIcon ? "visible " : "hidden",
                  }}
                >
                  <span
                    className="navbar_span"
                    onClick={(ev) => changeLanguage("G")}
                    style={{ marginTop: "15px" }}
                  >
                    <Flag
                      style={{ paddingRight: "2vh" }}
                      code="de"
                      width="25"
                    />
                    Deutchland
                  </span>
                </div>
              </div>
            </li>
          </ul>
        ) : (
          <div className="menuMobile">
            <div
              className={isActive ? "toogle active" : "toogle"}
              onClick={handleClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>

            {isActive == true ? (
              <ul className="navigation mobileNavigation">
                <li>
                  <Link style={{ textDecoration: "none" }} to="/">
                    <a>
                      {language == "S"
                        ? "Početna"
                        : language == "E"
                        ? "Home"
                        : "Startseite"}
                    </a>
                  </Link>
                </li>

                <li>
                  {/* ovo je za proizvode, viewport za racunar i telefon  */}

                  <div>
                    <div className="product_meni ">
                      <Link
                        onClick={(ev) => changeVisibility(true)}
                        style={{ textDecoration: "none" }}
                        to="/product"
                      >
                        <a style={{ color: visible ? "#5c5c5c" : "#1c1d20" }}>
                          {language == "S"
                            ? "Proizvodi"
                            : language == "E"
                            ? "Products"
                            : "Producte"}
                        </a>
                      </Link>

                      {visible ? (
                        <div>
                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to="/product"
                            onClick={change_page}
                          >
                            <span className="navbar_span mobile_span_1">
                              {language == "S"
                                ? "Kočioni cilindri"
                                : language == "E"
                                ? "Brake cylinders"
                                : "Bremszylinder"}
                            </span>
                          </Link>

                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to="/reparation"
                          >
                            <span className="navbar_span mobile_span_2">
                              {language == "S"
                                ? "Reparacija kocionih cilidara"
                                : language == "E"
                                ? "Reparation of brake cylinders"
                                : "Reparatur der Bremszylinder"}
                            </span>
                          </Link>
                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to="/kociona_klesta"
                          >
                            <span className="navbar_span mobile_span_3">
                              {language == "S"
                                ? "Kociona kljesta"
                                : language == "E"
                                ? "Brake caliper pistons"
                                : "Kolben für Bremssattel"}
                            </span>
                          </Link>
                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to="/garniture_gumica"
                          >
                            <span className="navbar_span mobile_span_4">
                              {language == "S"
                                ? "Garniture gumica"
                                : language == "E"
                                ? "Sealing kits"
                                : "Gummi-Garnitur"}
                            </span>
                          </Link>

                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to="/vinogradarska_sidra"
                          >
                            <span className="navbar_span mobile_span_5">
                              {language == "S"
                                ? "Vinogradarska sidra"
                                : language == "E"
                                ? "Vineyard screw anchors"
                                : "Weinbau Anker"}
                            </span>
                          </Link>
                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to="/masinska_obrada"
                          >
                            <span className="navbar_span mobile_span_6">
                              {language == "S"
                                ? "Obrada metala"
                                : language == "E"
                                ? "Metalworking processes"
                                : "Maschinelle Metallbearbeitung"}
                            </span>
                          </Link>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </li>

                <li>
                  <Link style={{ textDecoration: "none" }} to="/about">
                    <a>
                      {language == "S"
                        ? "O nama"
                        : language == "E"
                        ? "About"
                        : "Über uns"}
                    </a>
                  </Link>
                </li>

                <li>
                  <Link style={{ textDecoration: "none" }} to="/contact">
                    <a>
                      {" "}
                      {language == "S"
                        ? "Kontakt"
                        : language == "E"
                        ? "Contact"
                        : "Kontakt"}
                    </a>
                  </Link>
                </li>

                <li>
                  <div className="icon_meni">
                    <div
                      onClick={(ev) => changeVisibility(false)}
                      style={{
                        textDecoration: "none",
                        width: "50%",
                      }}
                    >
                      <div className="language_div">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Link style={{ textDecoration: "none" }}>
                            <a>
                              {language == "S"
                                ? "Jezik"
                                : language == "E"
                                ? "language"
                                : "Sprache"}
                            </a>
                          </Link>
                          <LanguageTwoToneIcon
                            style={{
                              marginTop: "1.2vh",
                              fontSize: "4vh",

                              position: "relative",
                              zIndex: "-2",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {visibleIcon ? (
                      <div>
                        <div>
                          <span
                            className="navbar_span mobile_span_1_language "
                            onClick={(ev) => changeLanguage("S")}
                          >
                            <Flag code="srb" width="25" />
                            Serbian
                          </span>
                        </div>
                        <div>
                          <span
                            className="navbar_span mobile_span_2_language"
                            onClick={(ev) => changeLanguage("E")}
                          >
                            <Flag code="gb" width="25" />
                            English
                          </span>
                        </div>

                        <div>
                          <span
                            className="navbar_span mobile_span_3_language"
                            onClick={(ev) => changeLanguage("G")}
                          >
                            <Flag code="de" width="25" />
                            German
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </li>
              </ul>
            ) : (
              <div style={{ marginTop: "50px", width: "100px" }}></div>
            )}
          </div>
        )

        /* <ul className="navigation">
        {windowSize.current[0] > 767 ? (
          <li>
            <Link style={{ textDecoration: "none" }} to="/">
              <p>
                {language == "S"
                  ? "Početna"
                  : language == "E"
                  ? "Home"
                  : "Startseite"}
              </p>
            </Link>
          </li>
        ) : (
          <li>
            <Link style={{ textDecoration: "none" }} to="/">
              <a>
                {language == "S"
                  ? "Početna"
                  : language == "E"
                  ? "Home"
                  : "Startseite"}
              </a>
            </Link>
          </li>
        )}

        <li>

          {windowSize.current[0] > 767 ? (
            <div>
              <div
                onMouseLeave={(ev) => changeVisibility(true)}
                className="product_meni"
              >
                <Link style={{ textDecoration: "none" }} to="/product">
                  <p
                    style={{ color: visible ? "#5c5c5c" : "#1c1d20" }}
                    onMouseOver={(ev) => changeVisibility(true)}
                  >
                    {language == "S"
                      ? "Proizvodi"
                      : language == "E"
                      ? "Products"
                      : "Producte"}
                  </p>
                </Link>
                <Link
                  to="/product"
                  style={{
                    textDecoration: "none",
                    visibility: visible ? "hidden" : "visible",
                  }}
                  onClick={change_page}
                >
                  <span className="navbar_span">
                    {language == "S"
                      ? "Kočioni cilindri"
                      : language == "E"
                      ? "Brake cylinders"
                      : "Bremszylinder"}
                  </span>
                </Link>

                <Link
                  style={{
                    textDecoration: "none",
                    visibility: visible ? "hidden" : "visible",
                  }}
                  to="/reparation"
                >
                  <span className="navbar_span" style={{ marginTop: "-5px" }}>
                    {language == "S"
                      ? "Reparacija kočionih cilindara"
                      : language == "E"
                      ? "Reparation of brake cylinders"
                      : "Reparatur der Bremszylinder"}
                  </span>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    visibility: visible ? "hidden" : "visible",
                  }}
                  to="/kociona_klesta"
                >
                  <span className="navbar_span" style={{ marginTop: "20px" }}>
                    {language == "S"
                      ? "Klipovi za kočiona klešta"
                      : language == "E"
                      ? "Brake caliper pistons"
                      : "Kolben für Bremssattel"}
                  </span>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    visibility: visible ? "hidden" : "visible",
                  }}
                  to="/garniture_gumica"
                >
                  <span className="navbar_span" style={{ marginTop: "42px" }}>
                    {language == "S"
                      ? " Garniture gumica"
                      : language == "E"
                      ? "Sealing kits"
                      : "Gummi-Garnitur"}
                  </span>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    visibility: visible ? "hidden" : "visible",
                  }}
                  to="/vinogradarska_sidra"
                >
                  <span className="navbar_span" style={{ marginTop: "65px" }}>
                    {language == "S"
                      ? "Vinogradarska sidra"
                      : language == "E"
                      ? "Vineyard screw anchors"
                      : "Weinbau Anker"}
                  </span>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    visibility: visible ? "hidden" : "visible",
                  }}
                  to="/masinska_obrada"
                >
                  <span className="navbar_span" style={{ marginTop: "90px" }}>
                    {language == "S"
                      ? "Mašinska obrada metala"
                      : language == "E"
                      ? "Metalworking processes"
                      : "Maschinelle Metallbearbeitung"}
                  </span>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <div className="product_meni">
                <Link
                  onClick={(ev) => changeVisibility(true)}
                  style={{ textDecoration: "none" }}
                  to="/product"
                >
                  <a style={{ color: visible ? "#5c5c5c" : "#1c1d20" }}>
                    {language == "S"
                      ? "Proizvodi"
                      : language == "E"
                      ? "Products"
                      : "Producte"}
                  </a>
                </Link>

                {visible ? (
                  <div>
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to="/product"
                      onClick={change_page}
                    >
                      <span className="navbar_span">
                        {language == "S"
                          ? "Kočioni cilindri"
                          : language == "E"
                          ? "Brake cylinders"
                          : "Bremszylinder"}
                      </span>
                    </Link>

                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to="/reparation"
                    >
                      <span className="navbar_span">
                        {language == "S"
                          ? "Reparacija kocionih cilidara"
                          : language == "E"
                          ? "Reparation of brake cylinders"
                          : "Reparatur der Bremszylinder"}
                      </span>
                    </Link>
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to="/kociona_klesta"
                    >
                      <span className="navbar_span">
                        {language == "S"
                          ? "Kociona kljesta"
                          : language == "E"
                          ? "Brake caliper pistons"
                          : "Kolben für Bremssattel"}
                      </span>
                    </Link>
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to="/garniture_gumica"
                    >
                      <span className="navbar_span">
                        {language == "S"
                          ? "Garniture gumica"
                          : language == "E"
                          ? "Sealing kits"
                          : "Gummi-Garnitur"}
                      </span>
                    </Link>

                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to="/vinogradarska_sidra"
                    >
                      <span className="navbar_span">
                        {language == "S"
                          ? "Vinogradarska sidra"
                          : language == "E"
                          ? "Vineyard screw anchors"
                          : "Weinbau Anker"}
                      </span>
                    </Link>
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to="/masinska_obrada"
                    >
                      <span className="navbar_span">
                        {language == "S"
                          ? "Obrada metala"
                          : language == "E"
                          ? "Metalworking processes"
                          : "Maschinelle Metallbearbeitung"}
                      </span>
                    </Link>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          )}
        </li>

        {windowSize.current[0] > 767 ? (
          <li>
            <Link style={{ textDecoration: "none" }} to="/about">
              <p>
                {language == "S"
                  ? "O nama"
                  : language == "E"
                  ? "About"
                  : "Über uns"}
              </p>
            </Link>
          </li>
        ) : (
          <li>
            <Link style={{ textDecoration: "none" }} to="/about">
              <a>
                {language == "S"
                  ? "O nama"
                  : language == "E"
                  ? "About"
                  : "Über uns"}
              </a>
            </Link>
          </li>
        )}

        {windowSize.current[0] > 767 ? (
          <li>
            <Link style={{ textDecoration: "none" }} to="/contact">
              <p>
                {" "}
                {language == "S"
                  ? "Kontakt"
                  : language == "E"
                  ? "Contact"
                  : "Kontakt"}
              </p>
            </Link>
          </li>
        ) : (
          <li>
            <Link style={{ textDecoration: "none" }} to="/contact">
              <a>
                {" "}
                {language == "S"
                  ? "Kontakt"
                  : language == "E"
                  ? "Contact"
                  : "Kontakt"}
              </a>
            </Link>
          </li>
        )}

        <li>
          {windowSize.current[0] > 767 ? (
            <div
              onMouseLeave={(ev) => changeVisibility(false)}
              className="icon_meni"
            >
              <div
                onMouseOver={(ev) => changeVisibility(false)}
                // style={{
                //   textDecoration: "none",
                //   width: "10vh",
                //   height: "5.5vh",
                // }}
              >
                <div
                  className="language_div"
                  style={{ display: "flex", flexFlow: "row" }}
                >
                  <Link style={{ textDecoration: "none" }}>
                    <p>
                      {language == "S"
                        ? "Jezik"
                        : language == "E"
                        ? "language"
                        : "Sprache"}
                    </p>
                  </Link>
                  <LanguageTwoToneIcon
                    style={{
                      marginTop: "5vh",
                      fontSize: "4vh",

                      position: "relative",
                      zIndex: "-2",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  textDecoration: "none",
                  visibility: visibleIcon ? "hidden" : "visible",
                }}
              >
                <span
                  className="navbar_span"
                  onClick={(ev) => changeLanguage("S")}
                >
                  <Flag
                    style={{ marginTop: "-30px", paddingRight: "2vh" }}
                    code="srb"
                    width="25"
                  />
                  Serbian
                </span>
              </div>
              <div
                style={{
                  textDecoration: "none",
                  visibility: visibleIcon ? "hidden" : "visible",
                }}
              >
                <span
                  className="navbar_span"
                  onClick={(ev) => changeLanguage("E")}
                  style={{
                    marginTop: "-10px",
                  }}
                >
                  <Flag style={{ paddingRight: "2vh" }} code="gb" width="25" />
                  English
                </span>
              </div>
              <div
                style={{
                  textDecoration: "none",
                  visibility: visibleIcon ? "hidden" : "visible",
                }}
              >
                <span
                  className="navbar_span"
                  onClick={(ev) => changeLanguage("G")}
                  style={{ marginTop: "15px" }}
                >
                  <Flag style={{ paddingRight: "2vh" }} code="de" width="25" />
                  Deutchland
                </span>
              </div>
            </div>
          ) : (
            <div className="icon_meni">
              <div
                onClick={(ev) => changeVisibility(false)}
                style={{
                  textDecoration: "none",
                  width: "50%",
                }}
              >
                <div className="language_div">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <a>
                        {language == "S"
                          ? "Jezik"
                          : language == "E"
                          ? "language"
                          : "Sprache"}
                      </a>
                    </Link>
                    <LanguageTwoToneIcon
                      style={{
                        marginTop: "1.2vh",
                        fontSize: "4vh",

                        position: "relative",
                        zIndex: "-2",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  textDecoration: "none",
                  visibility: visibleIcon ? "hidden" : "visible",
                }}
              >
                <span
                  className="navbar_span"
                  onClick={(ev) => changeLanguage("S")}
                >
                  <Flag code="srb" width="25" />
                  Serbian
                </span>
              </div>
              <div
                style={{
                  textDecoration: "none",
                  visibility: visibleIcon ? "hidden" : "visible",
                }}
              >
                <span
                  className="navbar_span"
                  onClick={(ev) => changeLanguage("E")}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Flag code="gb" width="25" />
                  English
                </span>
              </div>

              <div
                style={{
                  textDecoration: "none",
                  visibility: visibleIcon ? "hidden" : "visible",
                }}
              >
                <span
                  className="navbar_span"
                  onClick={(ev) => changeLanguage("G")}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Flag code="de" width="25" />
                  German
                </span>
              </div>
            </div>
          )}
        </li>
      </ul> */
      }
    </div>
  );
};

export default Navbar;
