import { React, useEffect } from "react";
import "./about.css";
import trustPNG from "../../assets/trust.png";
import KutijaVelika from "../../assets/cilindri/cilindri webp/kutijaVelika.webp";
import MetaDecorator from "../../functions/MetaDecorator";

const About = (props) => {
  useEffect(() => {
    const section = document.querySelector("#scroll");
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <div className="parent_about">
      <MetaDecorator
        title="Max Tehnika Knic - O nama"
        description="Nakon dugogodišnjeg iskustva u proizvodnji kočionih cilindara, raznih komponenata za putnička i teretna vozila, u raznovrsnoj mašinskoj obradi metala, mi Vam nudimo kvalitet i sigurnost."
      />
      <section className="child_about">
        <section className="section_divide_first_about">
          <h1 id="scroll">
            {props.language == "S"
              ? "O nama:"
              : props.language == "E"
              ? "About:"
              : " Über uns"}
          </h1>
          <h2>
            {props.language == "S"
              ? "Naše porodično preduzeće počelo je sa radom 2013. godine u opštini Knić i za veoma kratko vreme uspeli smo da sa proizvodima odličnog kvaliteta steknemo poverenje naših kupaca. Nakon dugogodišnjeg iskustva u proizvodnji kočionih cilindara, raznih komponenata za putnička i teretna vozila, u raznovrsnoj mašinskoj obradi metala, bravarskim radovima, izradi metalnih konstrukcija i poljoprivrednih priključnih mašina i uz pomoć kvalitetne opreme za proizvodnju proizvoda iz gore navedenog programa, mi Vam nudimo kvalitet i sigurnost."
              : props.language == "E"
              ? "Our family business was established in 2013 in the municipality of Knic and in a very short time we succeeded in gaining the trust of our customers with products of excellent quality. With many years’ of experience in the production of brake parts and various components for motor vehicles, of diverse metalworking processes and other machining processes, the production of metal constructions and agricultural machinery attachments, combined with excellent equipment we offer you quality and safety."
              : "Unser Familienunternehmen wurde 2013. in der Gemeinde Knić gegründet. In kurzer Zeit haben wir mit unseren Produkten mit hoher Qualität geschafft, das Vertrauen unserer Kunden zu bekommen. Nach unseren langjährigen Erfahrungen in der Bremszylinderfertigung, verschiedener Komponenten für PKW und LKW, maschineller Bearbeitung, Schloßerarbeit, Erstellung der Metallkonstruktionen und wirschaftlicher Anschlusmaschinen, und mit obengenannten Ausrüstung für Produktherstellung, wir bieten Ihnen Qualität und Sicherheit an. "}
          </h2>
        </section>
        <section className="section_divide_second_about">
          <img className="box_1" src={KutijaVelika}></img>
          <img className="trustIMG" src={trustPNG}></img>
          <img className="box_2" src={KutijaVelika}></img>
          <img className="box_3" src={KutijaVelika}></img>
        </section>
      </section>
    </div>
  );
};

export default About;

// Veliki broj vlasnika uvoznih traktora i ostalih poljoprivrednih mašina imaju problem sa kočionim sistemima. Za mnoge modele je veoma teško nabaviti nove kočione cilindre ili su novi kočioni cilindri veoma skupi. Iz ovih razloga povoljno vršimo reparaciju istih, na sledeći način:

// – stara armatura se izbacuje iz cilindra

// – cilindar se dovodi na novu veću meru da bi se napravila površina cilindra koja se može kvalitetno ishonovati kako bi se eliminisala oštećenja cilindra

// – nakon dobijanja praktično nove ishonovane površine konstruišemo novu kočionu armaturu sa novim kočionim gumicama i dobijamo, može se reći, nov cilindar.

// Ova reparacija je izvodljva ako oštećenja nisu toliko duboka, kako proširivanjem prečnika cilindra ne bi kritično bila smanjena debljina zida.
