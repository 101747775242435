import React, { useEffect } from "react";
import "./all_product.css";
import VinogradarksaSidra from "../../assets/ostali_proizvodi/vinogradarskaSidra.jpg";
import MetaDecorator from "../../functions/MetaDecorator";

export default function VinogradarskaSidra(props) {
  useEffect(() => {
    const section = document.querySelector("#scroll");
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);
  return (
    <div className="parent_all_product">
      <MetaDecorator
        title="Max Tehnika Knic - Vinogradarska sidra"
        description="Pravimo vinogradarska sidra različitih dimenzija po potrebi kupca. Kvalitetna izrada i niske cene. Pogledajte naš vebsajt i pozovite za više detalja."
      />
      <div className="child_all_product">
        <h1 id="scroll" className="h1_all_product">
          {props.language == "S"
            ? "    Vinogradarska sidra"
            : props.language == "E"
            ? "Vineyard screw anchors "
            : "Weinbau Anker"}
        </h1>
        <section>
          <div className={"image_div"}>
            <img
              className="image_part"
              style={{ height: "400px", width: "auto" }}
              src={VinogradarksaSidra}
              alt="cilindar"
            />
          </div>
        </section>
      </div>
    </div>
  );
}
