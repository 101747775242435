import React, { useEffect, useState } from "react";
import "./reparacija.css";
import Carousel from "./carousel.js";
import MetaDecorator from "../../functions/MetaDecorator";

export default function Reparacija(props) {
  const [selectedItem, setSelectedItem] = useState(null);

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    const section = document.querySelector("#scroll");
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, []);

  return (
    <div className="parent_reparacija">
      <MetaDecorator
        title="Max Tehnika Knic - Reparacija kočionih cilindara"
        description="Inovativnih procesima dobija se tako reći nov cilindar sa garancijom od 2 godine. Vršimo reparaciju kočionih cilindara. Zamenu gumica. Restauracijom konstruišemo novu konstrukciju klipa i dobija se možemo reći nov cilindar. Remont kočionih cilindara"
      />
      <section className="child_reparacija">
        <section className="section_divide_first_reparacija">
          <h1 id="scroll">
            {props.language == "S"
              ? "Reparacija kočionih cilindara:"
              : props.language == "E"
              ? "Reparation of brake cylinders:"
              : "Reparatur der Bremszylinder"}
          </h1>
          <h2>
            {props.language == "S"
              ? "Veliki broj vlasnika uvoznih traktora, ostalih poljoprivrednih i radnih mašina, kamiona i automobila imaju problem sa kočionim sistemima. Za mnoge modele je veoma teško nabaviti nove kočione cilindre ili su novi kočioni cilindri veoma skupi. Iz ovih razloga povoljno vršimo reparaciju istih, na sledeći način:"
              : props.language == "E"
              ? "A great number of owners of imported tractors and other agricultural machinery have problems with brake systems. It is quite difficult to purchase new brake cylinders for many models or new brake cylinders are very expensive. Because of these reasons, we are offering affordable reparation of brake cylinders done according to following order:"
              : "Viele Eigentümer der Importtraktoren und anderer landwirtschaftlichen Maschinen haben Probleme mit dem Bremssystem. Es ist sehr schwierig die Bremszylinder für viele Modelle zu beschaffen bzw. die neuen Bremszylinder sind sehr teuer. Aus diesen Gründen bieten wir günstige Reparation an, und das auf folgender Weise:"}
          </h2>
          <ul style={{ listStyle: "none" }}>
            <li>
              <h2>
                {props.language == "S"
                  ? "– stari klipovi,gumice i opruge se izbacuje iz cilindra,"
                  : props.language == "E"
                  ? "-the old armature is removed,"
                  : "–die alte Armatur wird aus dem Zylinder entfernt"}
              </h2>
            </li>
            <li>
              <h2>
                {props.language == "S"
                  ? "– cilindar se dovodi na novu veću meru da bi se napravila površina cilindra koja se može kvalitetno ishonovati kako bi se eliminisala oštećenja cilindra,"
                  : props.language == "E"
                  ? "-the cylinder internal surface is expanded so that the subsequent honing would be successful and all of the defects eliminated,"
                  : "–Der Zylinder wird auf das neue größere Maß gebracht, um die Zylinderfläche zu machen, die man feinschleifen kann. Auf dieser Weise wird die Zylinderbeschädigung entfernt."}
              </h2>
            </li>
            <li>
              <h2>
                {props.language == "S"
                  ? "– nakon dobijanja praktično nove ishonovane površine konstruišemo novu konstrukciju klipova sa novim kočionim gumicama i dobijamo, može se reći, nov cilindar."
                  : props.language == "E"
                  ? "-after getting practically new honed surface, a new brake armature, with a new sealing kit, is constructed and, it can be said, a new cylinder is made."
                  : "–Nachdem wir eine praktisch neue feingeschliffene Fläche bekommen haben, wird eine neue Bremsarmatur mit neuen Brems-Gummi konstruiert, und so bekommen wir, praktisch gesagt, einen neuen Zylinder."}
              </h2>
            </li>
          </ul>

          <h2>
            {props.language == "S"
              ? "Ova reparacija je izvodljva ako oštećenja nisu toliko duboka, kako proširivanjem prečnika cilindra ne bi kritično bila smanjena debljina zida."
              : props.language == "E"
              ? "This reparation is feasible if defects are not too deep, so that by expanding the diameter, the width of the cylinder is not critically downsized."
              : "So eine Reparatur ist nur möglich, wenn die Beschädigung nicht so tief ist, weil die Wandstärke bei der Durchmesservergrößung kritisch dünn sein könnte."}
          </h2>
        </section>
        <section className="section_divide_second_reparacija">
          <div className="selected_image_div">
            <img
              className={
                isActive
                  ? "show_img selected_image"
                  : "hide_img  selected_image"
              }
              onClick={handleClick}
              src={selectedItem}
            />
          </div>

          <Carousel
            handleSelectedItem={handleSelectedItem}
            handleClick={handleClick}
            isActive={isActive}
          />
        </section>
      </section>
    </div>
  );
}
