import React from "react";
import "./footer.css";
import Logo from "../../assets/Logo_transparent.png";
import Facebook from "../../assets/icons/facebook.png";
import Icons from "./icons";

import { FaAddressBook, FaPhoneAlt, FaMailBulk } from "react-icons/fa";

export default function App(props) {
  return (
    <div>
      {/* <hr></hr> */}
      <footer className="footer-distributed">
        <div className="footer-left">
          <img
            src={Logo}
            style={{ width: "80%", flex: "start" }}
            alt="Max tehnika knic"
          />

          <p className="footer-links">
            <a href="#" className="link-1">
              {props.language == "S"
                ? "Početna"
                : props.language == "E"
                ? "Home"
                : "Sprache"}
            </a>

            <a href="#">
              {props.language == "S"
                ? "Proizvodi"
                : props.language == "E"
                ? "Product"
                : "Producte"}
            </a>

            <a href="#">
              {props.language == "S"
                ? "O nama "
                : props.language == "E"
                ? "About us"
                : "Über uns"}
            </a>

            <a href="#">
              {props.language == "S"
                ? "Kontakt "
                : props.language == "E"
                ? "Contact"
                : "Kontakt"}
            </a>
          </p>

          <p className="footer-company-name">Max Tehnika Knic © 2015</p>
          <br></br>
          <p className="footer-company-name">contributors</p>
          <p className="footer-company-name"> photos:</p>
          <p className="footer-company-name">
            <a href=" https://www.instagram.com/jovana_eric16/?igshid=YmMyMTA2M2Y%3D">
              jovana_eric16
            </a>
          </p>
        </div>

        <div className="footer-center">
          <div style={{ paddingTop: "40px" }}>
            <div className="div_icon_first">
              <div
                className="div_icon_border"
                style={{ background: "#1c1d20", borderRadius: "25px" }}
              >
                <FaAddressBook
                  className="svg_icon_first"
                  style={{ color: "#5d5d5e" }}
                />
              </div>
              <p>Obor kneza Todosija 83 34240 Knic, Srbija</p>
              {/* <i
              className="fa fa-map-marker"
              style={{ fontSize: "15px", color: "#92999f " }}
            ></i> */}
            </div>
            <div className="div_icon_first">
              <div
                className="div_icon_border"
                style={{ background: "#1c1d20", borderRadius: "25px" }}
              >
                <FaPhoneAlt
                  className="svg_icon_first"
                  style={{ color: "#5d5d5e" }}
                />
              </div>{" "}
              <p>
                {props.language == "S"
                  ? "telefon: 065 3221324 , 061 1605506:"
                  : "phone: 065 3221324 , 061 1605506"}{" "}
              </p>
            </div>

            <div className="div_icon_first">
              <div
                className="div_icon_border"
                style={{ background: "#1c1d20", borderRadius: "25px" }}
              >
                <FaMailBulk
                  className="svg_icon_first"
                  style={{ color: "#5d5d5e" }}
                />
              </div>

              <a href="mailto:maxtehnikaknic@gmail.com">
                maxtehnikaknic@gmail.com
              </a>
            </div>
          </div>
        </div>

        <div className="footer-right">
          {props.language == "S" ? (
            <p className="footer-company-about">
              <h4>O kompaniji:</h4>
              Nakon dugogodišnjeg iskustva u proizvodnji kočionih cilindara,
              raznih komponenata za putnička i teretna vozila, u raznovrsnoj
              mašinskoj obradi metala, mi Vam nudimo kvalitet i sigurnost.
            </p>
          ) : props.language == "E" ? (
            <p className="footer-company-about">
              <h4>About the company:</h4>
              With many years’ of experience in the production of brake parts
              and various components for motor vehicles, combined with excellent
              equipment we offer you quality and safety.
            </p>
          ) : (
            <p className="footer-company-about">
              <h4>Über das Unternehmen:</h4>
              Nach unseren langjährigen Erfahrungen in der
              Bremszylinderfertigung, verschiedener Komponenten für PKW und LKW,
              maschineller Bearbeitung, Schloßerarbeit, Erstellung der
              Metallkonstruktionen und wirschaftlicher Anschlusmaschinen, und
              mit obengenannten Ausrüstung für Produktherstellung, wir bieten
              Ihnen Qualität und Sicherheit an.
            </p>
          )}

          <div className="footer-icons">
            <Icons />
          </div>
        </div>
      </footer>
    </div>
  );
}
